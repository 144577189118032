import React, { Component } from "react";
import { Link } from "react-router-dom";

class Rodape extends Component {
  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <div>
        <div className="b-info">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-xs-6">
                <address
                  className="b-info__contacts wow zoomInUp"
                  data-wow-delay="0.3s"
                >
                  <p className="titulo-rodape">SOBRE NÓS</p>
                  <div className="b-info__contacts-item">
                    <em>{this.props.revenda.texto_sobre_nos_site}</em>
                  </div>
                </address>
              </div>
              {/* <div className='botoesFlutuantes'>
                                <div className='iconeGoogle'>
                                    <a href="https://maps.app.goo.gl/MzhKRnEAQ9ccFbZB6" target="_blank" >
                                    <img src="/img/google.png" />   
                                    </a> </div>
                                <div className='iconeTiktok'>
                                    <a href="http://www.tiktok.com/@spcarszn" target="_blank" >
                                    <img src="/img/tiktok.png" />   
                                    </a> </div>
                                <div className='iconeKwai'>
                                    <a href="https://m.kwai.com/user/@spcars?kpn=KWAI&share_device_id=9CE4B151-03D6-4DC9-AEEE-89506A87EEEE&share_item_type=profile&share_id=9CE4B151-03D6-4DC9-AEEE-89506A87EEEE_1687286469542&language=pt-BR&share_uid=150000227679002&fid=150000227679002&cc=WHATS_APP&timestamp=1687286469542&translateKey=share_profile_default_word&shareBucket=br&pwa_source=share&shareCountry=BRA&shareBiz=profile&share_item_info=%40spcars&ept=v2&short_key=pka00CTR" target="_blank" >
                                    <img src="/img/kwai.png" />   
                                    </a>
                                </div>
                            </div> */}
              <div className="col-md-3 col-xs-6">
                <div className="b-info__latest">
                  <h3 className="titulo-rodape">ATENDIMENTO</h3>
                  <address
                    className="b-info__contacts wow zoomInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="b-info__contacts-item">
                      <em>
                        Seg-Sex : {this.props.revenda.horario_seg_sex}
                        <br />
                        Sab : {this.props.revenda.horario_sab}
                      </em>
                    </div>
                  </address>
                </div>
              </div>
              <div className="col-md-3 col-xs-6">
                <address
                  className="b-info__contacts wow zoomInUp"
                  data-wow-delay="0.3s"
                >
                  <p className="titulo-rodape">FALE CONOSCO</p>
                  <div className="b-info__contacts-item">
                    <span className="fa fa-map-marker"></span>
                    <em>
                      {" "}
                      {this.props.revenda.peendereco_fiscal}{" "}
                      {this.props.revenda.peendereco_fiscal_numero}{" "}
                      {this.props.revenda.peendereco_fiscal_compl} -{" "}
                      {this.props.revenda.pebairro_fiscal}
                      <br />
                      {this.props.revenda.pecidade}-{this.props.revenda.peuf}
                    </em>
                  </div>
                  <div className="b-info__contacts-item">
                    <span className="fa fa-phone"></span>
                    <em>
                      Telefone:{" "}
                      <a
                        href={
                          "tel:" +
                          retiraMascara(this.props.revenda.petelefone1, "link")
                        }
                      >
                        {retiraMascara(this.props.revenda.petelefone1)}
                      </a>
                    </em>
                  </div>
                  {this.props.revenda.petelefone2 !== undefined &&
                  this.props.revenda.petelefone2.trim().length > 0 ? (
                    <div className="b-info__contacts-item">
                      <span className="fa fa-whatsapp"></span>
                      <em>
                        Whatsapp:{" "}
                        <a
                          target="_blank"
                          href={
                            "https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55" +
                            retiraMascara(
                              this.props.revenda.petelefone2,
                              "link"
                            )
                          }
                        >
                          {retiraMascara(this.props.revenda.petelefone2)}
                        </a>
                      </em>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {this.props.revenda.petelefone3 !== undefined &&
                  this.props.revenda.petelefone3.trim().length > 0 ? (
                    <div className="b-info__contacts-item">
                      <span className="fa fa-whatsapp"></span>
                      <em>
                        Whatsapp:{" "}
                        <a
                          target="_blank"
                          href={
                            "https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55" +
                            retiraMascara(
                              this.props.revenda.petelefone3,
                              "link"
                            )
                          }
                        >
                          {retiraMascara(this.props.revenda.petelefone3)}
                        </a>
                      </em>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="b-info__contacts-item">
                    <span className="fa fa-envelope"></span>
                    <em>
                      E-mail:{" "}
                      <a href={"mailto:" + this.props.revenda.peemail}>
                        {this.props.revenda.peemail}
                      </a>
                    </em>
                  </div>
                </address>
              </div>
              <div className="col-md-3 col-xs-6">
                <address
                  className="b-info__contacts wow zoomInUp"
                  data-wow-delay="0.3s"
                >
                  <p className="titulo-rodape">VENHA NOS VISITAR</p>
                  <a
                    target="_blank"
                    href={this.props.revenda.mapa_link}
                    className="wow slideInLeft"
                    rel="noopener noreferrer"
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d457.3176336654782!2d-46.6071365!3d-23.5130342!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7f3d91ba78217c2c!2sSPCARS!5e0!3m2!1spt-BR!2sbr!4v1657654593969!5m2!1spt-BR!2sbr"
                      width="200"
                      height="200"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </a>
                </address>
              </div>
            </div>
          </div>
        </div>
        <footer className="b-footer">
          <div className="container">
            <div className="row">
              <div className="col-xs-4">
                <div
                  className="b-footer__company wow fadeInLeft"
                  data-wow-delay="0.3s"
                >
                  <div className="">
                    <Link to="/" className="wow slideInLeft logo_bottom">
                      <img
                        src="/img/logo.png"
                        alt={" Logotipo " + this.props.revenda.penome_fantasia}
                        style={{ marginBottom: "-10px" }}
                      />
                    </Link>
                  </div>
                  <p className="rodape-direitos">
                    {this.data_atual.getFullYear()} Todos os direitos
                    reservados. {this.props.revenda.penome_fantasia}.
                  </p>
                </div>
              </div>
              <div className="col-xs-8">
                <div
                  className="b-footer__content wow fadeInRight"
                  data-wow-delay="0.3s"
                  style={{ marginTop: "50px", marginRight: "40px" }}
                >
                  <div className="b-footer__content-social">
                    <a
                      href={this.props.revenda.facebook_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/facebook.png" alt="Logo Facebook" />
                    </a>
                    <a
                      href={this.props.revenda.instagram_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/instagram.png" alt="Logo Instagram" />
                    </a>
                    <a
                      href="https://maps.app.goo.gl/MzhKRnEAQ9ccFbZB6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/mapa.png" alt="Logo mapa" />
                    </a>
                    <a
                      href="http://www.tiktok.com/@spcarszn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/tiktok.png" alt="Logo TikTok" />
                    </a>
                    <a
                      href="https://m.kwai.com/user/@spcars?kpn=KWAI&share_device_id=9CE4B151-03D6-4DC9-AEEE-89506A87EEEE&share_item_type=profile&share_id=9CE4B151-03D6-4DC9-AEEE-89506A87EEEE_1687286469542&language=pt-BR&share_uid=150000227679002&fid=150000227679002&cc=WHATS_APP&timestamp=1687286469542&translateKey=share_profile_default_word&shareBucket=br&pwa_source=share&shareCountry=BRA&shareBiz=profile&share_item_info=%40spcars&ept=v2&short_key=pka00CTR"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/kwai.png" alt="Logo Kwai" />
                    </a>

                    {this.props.revenda.petelefone2 !== undefined &&
                    this.props.revenda.petelefone2.trim().length > 0 ? (
                      <a
                        href={
                          "https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55" +
                          retiraMascara(this.props.revenda.petelefone2, "link")
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="botao-whatsapp"
                      >
                        <i className="fa fa-whatsapp"></i>
                      </a>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <nav className="b-footer__content-nav">
                    <ul>
                      <li>
                        <Link to="/"> Início</Link>
                      </li>
                      <li>
                        <Link to="/venda-seu-veiculo"> Venda seu veículo</Link>
                      </li>
                      <li>
                        <Link to="/financiamento"> Financiamento</Link>
                      </li>
                      <li>
                        <Link to="/contato"> Contato</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = "";
  if (value !== undefined) {
    numero = value.replace("_", "");
    if (type === "link") {
      numero = numero
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(" ", "");
    }
  }
  return numero;
}

export default Rodape;
